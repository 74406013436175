<template>
  <div id="app">
    <form @submit.prevent>
      <label>
        Email*
        <input type="email" v-model="email" required />
      </label>
      <label>
        Password*
        <input type="password" v-model="password" required />
      </label>
      <button v-on:click="login">Log in</button>
      <p class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>
 
<script>
import API from "@/js/api";
import store from "@/store";
export default {
  store,
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    userIsLoading() {
      return this.$store.getters["auth/isLoading"];
    },
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  mounted: async function () {
    var self = this;
    await self.$store.dispatch("auth/getUser");
  },
  beforeMount() {
    this.handleRedirection();
  },
  methods: {
    async handleRedirection() {
      let isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated) {
        var redirectTo = this.$route.query.redirect;
        if (redirectTo) {
          this.$router.push(redirectTo);
        } else {
          this.$router.push("/");
        }
      }
    },
    //Log in with the form's email/username and password
    async login() {
      var data = {
        email: this.email,
        password: this.password,
      };
      if (data.email && data.password) {
        var response = await API.login(data);
        if (response.user && response.user.isAuthenticated) {
          var redirectTo = this.$route.query.redirect;
          this.$cookies.set("SID", response.cookie, "1d");
          await this.$store.dispatch("auth/setUser", { status: true });
          if (redirectTo) {
            this.$router.push(redirectTo);
          } else {
            this.$router.push("/");
          }
        } else {
          this.errorMessage = "Check and enter valid credentials.";
        }
      }
    },
  },
};
</script>
  
<style scoped>
button,
.error-message {
  color: red;
}
button {
  color: black;
}
input {
  display: block;
  margin-bottom: 10px;
}
</style>