<template>
    <h2>Hello {{user.name}}, Welcome to</h2>
    <h2>Integrately A/B Testing Portal</h2>
    <p>
        An ultimate tool for a/b testing.
    </p>
    <div>
    </div>
</template>

<script>
export default {	
	name: "Home",
	mixins: [],
	components: {
		
	},
	data: function () {
		return {
		};
	},
	computed: {
		isAuthenticated() {
			return this.$store.getters["auth/isAuthenticated"];
		},
		user() {
			return this.$store.getters["auth/user"];
		},
		userIsLoading() {
			return this.$store.getters["auth/isLoading"];
		},
		
	},	
    created(){
        //var self = this;
        //self.getExperiments();
    },
	methods: {
		/*async getExperiments(){
            var self = this;
            self.experiments = await API.getExperiments();
        }*/
	},
};
</script>