<template>
  <div id="app">
    <form @submit.prevent>
      <label>
        Name
        <input type="text" v-model="name" required />
      </label>
      <label>
        Email
        <input type="email" v-model="email" required />
      </label>
      <label>
        Password
        <input type="password" v-model="password" required />
      </label>
      <label>
        Verification key
        <input type="password" v-model="key" required />
      </label>
      <button v-on:click="signup">Sign Up</button>
      <p class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>
 
<script>
import API from "@/js/api";
import store from "@/store";
export default {
  store,
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    userIsLoading() {
      return this.$store.getters["auth/isLoading"];
    },
  },
  data() {
    return {
      email: "",
      password: "",
      name: "",
      key: "",
      errorMessage: "",
    };
  },
  mounted: async function () {
    var self = this;
    await self.$store.dispatch("auth/getUser");
  },
  methods: {
    async handleRedirection() {
      let isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated) {
        var redirectTo = this.$route.query.redirect;
        if (redirectTo) {
          this.$router.push(redirectTo);
        } else {
          this.$router.push("/");
        }
      }
    },
    //Signup with the form's email/username and password
    async signup() {
      var data = {
        name: this.name,
        email: this.email,
        password: this.password,
        key: this.key,
      };
      if (data.name && data.email && data.password && data.key) {
        var response = await API.signup(data);
        if (response.success) {
          this.$router.push("/login");
        } else {
          this.errorMessage = response.message || "Check and enter valid inputs";
        }
      }
    },
  },
};
</script>
  
<style scoped>
button,
.error-message {
  color: red;
}
button {
  color: black;
}
input {
  display: block;
  margin-bottom: 10px;
}
</style>