<template>
  <div id="app">
    <form @submit.prevent>
      <div>
        <h2>Hello {{ user.name }},</h2>
      </div>
      <h2>Available Experiments</h2>
      <div>
        <ul
          v-for="(exp, index) in experiments"
          v-bind:key="'experiment-' + index"
        >
          <li>
            {{ exp.name }} <button v-on:click="logExperiment">Test</button>
          </li>
        </ul>
      </div>
      <label>
        Experiment URL*
        <input type="text" v-model="containerUrl" required />
      </label>
      <button v-on:click="addExperiment">Add Experiment</button>
      <p class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>
<script>
import API from "@/js/api";
//import store from "@/store";
export default {
  name: "Dashboard",
  mixins: [],
  components: {},
  data: function () {
    return {
      containerUrl: "",
      experiments: [],
      errorMessage: "",
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    userIsLoading() {
      return this.$store.getters["auth/isLoading"];
    },
  },
  mounted: async function () {
    var self = this;
    await self.$store.dispatch("auth/getExperiments");
    self.getExperiments();
  },
  methods: {
    async logExperiment() {
      //var self = this;
      //var experiments = self.$store.getters["auth/experiments"];
      console.log(await API.getEnv());
    },
    async getExperiments() {
      var self = this;
      var experiments = this.$store.getters["auth/experiments"];
      if (experiments) {
        self.experiments = experiments;
      }
    },
    async addExperiment() {
      var containerID;
      if (this.containerUrl.startsWith("https")) {
        containerID = this.containerUrl.replace(
          "https://www.googleoptimize.com/optimize.js?id=",
          ""
        );
      } else if (this.containerUrl.startsWith("<script")) {
        this.containerUrl = this.containerUrl.replace(
          '<script src="https://www.googleoptimize.com/optimize.js?id=',
          ""
        );
        containerID = this.containerUrl.split('"')[0];
      } else if (this.containerUrl.startsWith("OPT")) {
        containerID = this.containerUrl;
      } else {
        this.errorMessage = "Please check and enter valid inputs";
      }
      if (containerID) {
        var response = await API.addExperiment({ id: containerID });
        console.log(response);
      }
      this.errorMessage = containerID;
    },
  },
};
</script>
<style scoped>
button,
.error-message {
  color: red;
}
button {
  color: black;
}
input {
  display: block;
  margin-bottom: 10px;
}
</style>